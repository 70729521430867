import moment from 'moment'
import numeral from 'numeral'

export default {
  methods: {
    dateFormatter (value) {
      return moment(value).format('Y-MM-DD')
    },

    dateTimeFormatter (value) {
      return moment(value).format('Y-MM-DD HH:mm:ss')
    },

    dateDayFormatter (value) {
      return moment(value).format('Y-MM-DD')
    },

    timeFormatter (value) {
      return moment(value).format('hh:mm a')
    },

    dateTimeLongFormatter (value) {
      return moment(value).format('MMM, DD hh:mm a')
    },

    dateTimeLocalFormatter (value) {
      return moment(value).format('YYYY-MM-DDTHH:mm')
    },

    numberFormatter (value) {
      return numeral(value).format('0,0.00')
    }
  }
}
